import { useState } from 'react';
import { DollarSign, MapPin, Home } from 'lucide-react';
import { useUser, SignInButton } from '@clerk/clerk-react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from './ui/button';
import { useNavigate } from 'react-router-dom';

interface SearchCriteria {
  price: boolean;
  location: boolean;
  size: boolean;
}

interface SearchValidationProps {
  onValidSearch: () => void;
}

export function SearchValidation({ onValidSearch }: SearchValidationProps) {
  const [searchInput, setSearchInput] = useState('');
  const [searchChunks, setSearchChunks] = useState<Array<{ text: string; type: string; color: string }>>([]);
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>({
    price: false,
    location: false,
    size: false,
  });
  const [isTransitioning, setIsTransitioning] = useState(false);
  const { isSignedIn } = useUser();
  const navigate = useNavigate();

  const analyzeInput = (input: string) => {
    const priceRegex = /\$?\d+(?:,\d{3})*(?:\.\d{2})?|\d+\s*(?:dollars?|USD)/i;
    const locationRegex = /(?:in|at|near|around)\s+([a-zA-Z\s,]+)/i;
    const sizeRegex = /(?:\d+\s*bed(?:room)?s?|studio|apartment|house|condo)/i;

    const newChunks: Array<{ text: string; type: string; color: string }> = [];
    const newCriteria = { ...searchCriteria };

    const priceMatch = input.match(priceRegex);
    if (priceMatch) {
      newChunks.push({
        text: priceMatch[0],
        type: 'price',
        color: 'bg-blue-100 text-blue-800'
      });
      newCriteria.price = true;
    }

    const locationMatch = input.match(locationRegex);
    if (locationMatch) {
      newChunks.push({
        text: locationMatch[0],
        type: 'location',
        color: 'bg-green-100 text-green-800'
      });
      newCriteria.location = true;
    }

    const sizeMatch = input.match(sizeRegex);
    if (sizeMatch) {
      newChunks.push({
        text: sizeMatch[0],
        type: 'size',
        color: 'bg-purple-100 text-purple-800'
      });
      newCriteria.size = true;
    }

    setSearchChunks(newChunks);
    setSearchCriteria(newCriteria);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchInput(value);
    analyzeInput(value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const hasAllCriteria = Object.values(searchCriteria).every(Boolean);
    
    if (hasAllCriteria) {
      sessionStorage.setItem('lastSearchQuery', searchInput);
      const searches = JSON.parse(sessionStorage.getItem('searchHistory') || '[]');
      searches.push({
        timestamp: Date.now(),
        queryText: searchInput
      });
      sessionStorage.setItem('searchHistory', JSON.stringify(searches));
      
      if (isSignedIn) {
        setIsTransitioning(true);
        setTimeout(() => {
          onValidSearch();
        }, 600);
      } else {
        sessionStorage.setItem('postLoginRedirect', '/chat');
        navigate('/sign-in');
      }
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const form = e.currentTarget.form;
      if (form) {
        const submitEvent = new Event('submit', { cancelable: true });
        form.dispatchEvent(submitEvent);
      }
    }
  };

  const removeChunk = (index: number) => {
    const chunk = searchChunks[index];
    setSearchChunks(searchChunks.filter((_, i) => i !== index));
    setSearchCriteria(prev => ({
      ...prev,
      [chunk.type]: false
    }));
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="w-full space-y-3 sm:space-y-4"
    >
      <motion.form 
        onSubmit={handleSubmit}
        animate={isTransitioning ? { opacity: 0 } : {}}
        transition={{ duration: 0.6, ease: "easeInOut" }}
      >
        <div className="relative flex items-center">
          <input
            type="text"
            value={searchInput}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Find me a 2 bed apartment..."
            className="w-full px-3 sm:px-4 py-2.5 sm:py-3 bg-background text-foreground rounded-xl border shadow-sm focus:outline-none focus:ring-2 focus:ring-primary text-sm pr-[100px] sm:pr-[120px]"
          />
          {isSignedIn ? (
            <button
              type="submit"
              disabled={!Object.values(searchCriteria).every(Boolean)}
              className="absolute right-2 top-1/2 -translate-y-1/2 px-4 sm:px-6 py-1.5 sm:py-2 bg-primary text-primary-foreground rounded-lg text-sm font-medium hover:bg-primary/90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Search →
            </button>
          ) : (
            <SignInButton mode="modal" afterSignInUrl="/chat">
              <Button
                type="button"
                className="absolute right-2 top-1/2 -translate-y-1/2 px-4 sm:px-6 py-1.5 sm:py-2 bg-primary text-primary-foreground rounded-lg text-sm font-medium hover:bg-primary/90 transition-colors"
              >
                Search →
              </Button>
            </SignInButton>
          )}
        </div>
      </motion.form>

      {searchChunks.length > 0 && (
        <motion.div 
          className="flex flex-wrap gap-1.5 sm:gap-2 mt-2"
          animate={isTransitioning ? { opacity: 0 } : {}}
          transition={{ duration: 0.6, ease: "easeInOut" }}
        >
          {searchChunks.map((chunk, index) => (
            <div
              key={index}
              className={`${chunk.color} px-2 sm:px-3 py-0.5 sm:py-1 rounded-full flex items-center gap-1.5 sm:gap-2 text-xs sm:text-sm`}
            >
              {chunk.text}
              <button
                onClick={() => removeChunk(index)}
                className="hover:bg-gray-200 dark:hover:bg-gray-700 rounded-full p-0.5 sm:p-1"
              >
                <svg
                  className="w-2.5 h-2.5 sm:w-3 sm:h-3"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          ))}
        </motion.div>
      )}

      <AnimatePresence>
        {!isTransitioning && (
          <motion.div 
            className="flex items-center justify-center gap-4 sm:gap-8 text-xs sm:text-sm text-muted-foreground flex-wrap"
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
          >
            <div className="flex items-center gap-1.5 sm:gap-2">
              <DollarSign className={`w-3.5 h-3.5 sm:w-4 sm:h-4 ${searchCriteria.price ? 'text-green-500' : ''}`} />
              Price
            </div>
            <div className="flex items-center gap-1.5 sm:gap-2">
              <MapPin className={`w-3.5 h-3.5 sm:w-4 sm:h-4 ${searchCriteria.location ? 'text-green-500' : ''}`} />
              Location
            </div>
            <div className="flex items-center gap-1.5 sm:gap-2">
              <Home className={`w-3.5 h-3.5 sm:w-4 sm:h-4 ${searchCriteria.size ? 'text-green-500' : ''}`} />
              Size
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}