import { useState, useEffect } from 'react';
import { SignIn, useUser } from '@clerk/clerk-react';
import { AnimatePresence } from 'framer-motion';
import { ChatHeader } from './chat/chat-header';
import { ChatMessages } from './chat/chat-messages';
import { ChatInput } from './chat/chat-input';
import { MultiStepLoader } from './ui/multi-step-loader';
import { SearchValidation } from './search-validation';
import { mockProperties } from '../data/mock-properties';
import type { ChatMessage } from '../types/chat';

export function ChatPage() {
  const { isSignedIn } = useUser();
  const [showChat, setShowChat] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    const lastQuery = sessionStorage.getItem('lastSearchQuery');
    if (lastQuery) {
      setShowLoader(true);
      setTimeout(() => {
        setShowLoader(false);
        setShowChat(true);
        handleInitialMessage(lastQuery);
      }, 3000);
      sessionStorage.removeItem('lastSearchQuery');
    }
  }, []);

  const handleInitialMessage = (query: string) => {
    const userMessage: ChatMessage = {
      id: Date.now().toString(),
      content: query,
      sender: 'user',
      timestamp: Date.now()
    };
    setMessages([userMessage]);
    simulateResponse();
  };

  const handleSendMessage = (message: string) => {
    const userMessage: ChatMessage = {
      id: Date.now().toString(),
      content: message,
      sender: 'user',
      timestamp: Date.now()
    };
    setMessages(prev => [...prev, userMessage]);
    simulateResponse();
  };

  const simulateResponse = () => {
    setIsTyping(true);
    setTimeout(() => {
      const response: ChatMessage = {
        id: Date.now().toString(),
        content: "Here are some properties that match your preferences:",
        sender: 'assistant',
        timestamp: Date.now(),
        properties: mockProperties
      };
      setMessages(prev => [...prev, response]);
      setIsTyping(false);
    }, 1500);
  };

  const handleValidSearch = () => {
    const query = sessionStorage.getItem('lastSearchQuery');
    if (query) {
      setShowLoader(true);
      setTimeout(() => {
        setShowLoader(false);
        setShowChat(true);
        handleInitialMessage(query);
      }, 3000);
    }
  };

  if (!isSignedIn) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-background">
        <div className="max-w-md w-full p-6">
          <SignIn afterSignInUrl="/chat" />
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen flex flex-col bg-background overflow-hidden">
      <ChatHeader />
      <main className="flex-1 flex flex-col h-[calc(100vh-3.5rem)] mt-14 overflow-hidden">
        <AnimatePresence mode="wait">
          {showLoader ? (
            <MultiStepLoader
              loadingStates={[
                { text: "Analyzing your search criteria..." },
                { text: "Finding matching properties..." },
                { text: "Preparing personalized recommendations..." },
                { text: "Almost ready..." }
              ]}
              loading={true}
              duration={750}
              loop={false}
            />
          ) : showChat ? (
            <>
              <ChatMessages messages={messages} isTyping={isTyping} />
              <ChatInput onSendMessage={handleSendMessage} isTyping={isTyping} />
            </>
          ) : (
            <div className="flex-1 flex items-center justify-center p-4">
              <div className="w-full max-w-xl">
                <SearchValidation onValidSearch={handleValidSearch} />
              </div>
            </div>
          )}
        </AnimatePresence>
      </main>
    </div>
  );
}
