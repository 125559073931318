import { useState } from 'react';
import { DollarSign, Bed, Bath, Ruler, Calendar, Building2 } from 'lucide-react';
import { Button } from '../ui/button';
import { FilterDropdown } from './filter-dropdown';
import { cn } from '@/lib/utils';

interface Filters {
  propertyType: string;
  priceRange: string;
  beds: string;
  baths: string;
  sqftRange: string;
  availability: string;
  status: string;
}

const propertyTypes = [
  { label: 'All Property Types', value: 'all' },
  { label: 'Apartment', value: 'apartment' },
  { label: 'House', value: 'house' },
  { label: 'Condo', value: 'condo' },
  { label: 'Studio', value: 'studio' },
  { label: 'Loft', value: 'loft' },
  { label: 'Townhouse', value: 'townhouse' }
];

const priceRanges = [
  { label: 'Any Price', value: 'any' },
  { label: 'Under $1,000', value: '0-1000' },
  { label: '$1,000 - $1,500', value: '1000-1500' },
  { label: '$1,500 - $2,000', value: '1500-2000' },
  { label: '$2,000 - $2,500', value: '2000-2500' },
  { label: '$2,500 - $3,000', value: '2500-3000' },
  { label: '$3,000 - $3,500', value: '3000-3500' },
  { label: '$3,500+', value: '3500-plus' }
];

const bedOptions = [
  { label: 'Any Beds', value: 'any' },
  { label: 'Studio', value: 'studio' },
  { label: '1+ Bed', value: '1' },
  { label: '2+ Beds', value: '2' },
  { label: '3+ Beds', value: '3' },
  { label: '4+ Beds', value: '4' },
  { label: '5+ Beds', value: '5' }
];

const bathOptions = [
  { label: 'Any Baths', value: 'any' },
  { label: '1+ Bath', value: '1' },
  { label: '1.5+ Baths', value: '1.5' },
  { label: '2+ Baths', value: '2' },
  { label: '2.5+ Baths', value: '2.5' },
  { label: '3+ Baths', value: '3' },
  { label: '4+ Baths', value: '4' }
];

const sqftRanges = [
  { label: 'Any Size', value: 'any' },
  { label: 'Under 500 sqft', value: '0-500' },
  { label: '500 - 750 sqft', value: '500-750' },
  { label: '750 - 1,000 sqft', value: '750-1000' },
  { label: '1,000 - 1,250 sqft', value: '1000-1250' },
  { label: '1,250 - 1,500 sqft', value: '1250-1500' },
  { label: '1,500+ sqft', value: '1500-plus' }
];

const availabilityOptions = [
  { label: 'Any Time', value: 'any' },
  { label: 'Available Now', value: 'now' },
  { label: 'Within 1 Week', value: '1week' },
  { label: 'Within 2 Weeks', value: '2weeks' },
  { label: 'Within 1 Month', value: '1month' },
  { label: 'Within 2 Months', value: '2months' }
];

export function PropertyFilters() {
  const [filters, setFilters] = useState<Filters>({
    propertyType: 'all',
    priceRange: 'any',
    beds: 'any',
    baths: 'any',
    sqftRange: 'any',
    availability: 'any',
    status: 'all'
  });

  const [activeStatus, setActiveStatus] = useState<'active' | 'all' | 'leased' | 'delisted'>('all');

  const handleFilterChange = (key: keyof Filters, value: string) => {
    setFilters(prev => ({
      ...prev,
      [key]: value
    }));
  };

  return (
    <div className="w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="container flex flex-wrap items-center gap-2 py-2">
        <div className="flex flex-wrap items-center gap-2 flex-1">
          <FilterDropdown
            icon={<Building2 className="h-4 w-4" />}
            label="Property Type"
            options={propertyTypes}
            value={filters.propertyType}
            onChange={(value) => handleFilterChange('propertyType', String(value))}
          />

          <FilterDropdown
            icon={<DollarSign className="h-4 w-4" />}
            label="Price"
            options={priceRanges}
            value={filters.priceRange}
            onChange={(value) => handleFilterChange('priceRange', String(value))}
          />

          <FilterDropdown
            icon={<Bed className="h-4 w-4" />}
            label="Beds"
            options={bedOptions}
            value={filters.beds}
            onChange={(value) => handleFilterChange('beds', String(value))}
          />

          <FilterDropdown
            icon={<Bath className="h-4 w-4" />}
            label="Baths"
            options={bathOptions}
            value={filters.baths}
            onChange={(value) => handleFilterChange('baths', String(value))}
          />

          <FilterDropdown
            icon={<Ruler className="h-4 w-4" />}
            label="Square Feet"
            options={sqftRanges}
            value={filters.sqftRange}
            onChange={(value) => handleFilterChange('sqftRange', String(value))}
          />

          <FilterDropdown
            icon={<Calendar className="h-4 w-4" />}
            label="Availability"
            options={availabilityOptions}
            value={filters.availability}
            onChange={(value) => handleFilterChange('availability', String(value))}
          />
        </div>

        <div className="flex gap-1">
          {(['active', 'all', 'leased', 'delisted'] as const).map((status) => (
            <Button
              key={status}
              variant={activeStatus === status ? 'default' : 'ghost'}
              size="sm"
              className={cn(
                "capitalize",
                activeStatus !== status && "text-muted-foreground"
              )}
              onClick={() => setActiveStatus(status)}
            >
              {status}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
}