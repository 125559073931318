import { Routes, Route, Navigate } from 'react-router-dom';
import { Cover } from './components/ui/cover';
import { ThemeToggle } from './components/theme-toggle';
import { SearchValidation } from './components/search-validation';
import { SearchResults } from './components/search-results';
import { ChatPage } from './components/chat-page';
import { PropertyDetails } from './components/property-details';
import { UserButton, useUser, SignIn } from '@clerk/clerk-react';
import { ViewMapButton } from './components/ui/view-map-button';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useEffect } from 'react';

function HomePage() {
  const { isSignedIn } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    const redirectPath = sessionStorage.getItem('postLoginRedirect');
    if (isSignedIn && redirectPath) {
      sessionStorage.removeItem('postLoginRedirect');
      navigate(redirectPath);
    }
  }, [isSignedIn, navigate]);

  const handleValidSearch = () => {
    navigate('/chat');
  };

  return (
    <div className="min-h-screen bg-background relative overflow-hidden">
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.4 }}
          transition={{ duration: 1.5 }}
          className="absolute -top-[20%] -right-[10%] w-[80%] sm:w-[60%] h-[60%] rounded-full bg-gradient-to-br from-blue-500/20 via-purple-500/20 to-emerald-500/20 blur-3xl"
        />
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.4 }}
          transition={{ duration: 1.5, delay: 0.2 }}
          className="absolute -bottom-[20%] -left-[10%] w-[80%] sm:w-[60%] h-[60%] rounded-full bg-gradient-to-tr from-emerald-500/20 via-blue-500/20 to-purple-500/20 blur-3xl"
        />
      </div>

      <header className="fixed top-0 w-full bg-background/80 backdrop-blur-sm z-50 border-b">
        <div className="container mx-auto px-3 sm:px-4">
          <div className="flex items-center justify-between h-14">
            <motion.a 
              href="/"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
              className="flex items-center h-8 sm:h-12"
            >
              <img
                src="https://logoblack.s3.us-east-1.amazonaws.com/logo-fac-B.png"
                alt="FindACrib Logo"
                className="h-full w-auto object-contain dark:hidden"
              />
              <img
                src="https://logowhite.s3.us-east-1.amazonaws.com/wide+logo+%40+cove+(2).png"
                alt="FindACrib Logo"
                className="h-full w-auto object-contain hidden dark:block"
              />
            </motion.a>
            <motion.div 
              className="flex items-center gap-2 sm:gap-4"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
            >
              {isSignedIn ? (
                <>
                  <ViewMapButton />
                  <UserButton afterSignOutUrl="/" />
                </>
              ) : (
                <ViewMapButton />
              )}
              <ThemeToggle />
            </motion.div>
          </div>
        </div>
      </header>

      <main className="flex flex-col items-center justify-center min-h-screen p-4">
        <div className="w-full max-w-4xl mx-auto -mt-16 relative z-10">
          <motion.div 
            className="hidden md:flex justify-center mb-8 sm:mb-12"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            <img
              src="https://palm.s3.us-east-1.amazonaws.com/palm+trees+%40+cove.png"
              alt=""
              className="h-20 sm:h-32 w-auto object-contain dark:hidden"
            />
            <img
              src="https://owl.s3.us-east-1.amazonaws.com/owl+%40+cove.png"
              alt=""
              className="h-20 sm:h-32 w-auto object-contain hidden dark:block"
            />
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="relative px-2 sm:px-4"
          >
            <div className="absolute -top-20 left-1/2 -translate-x-1/2 w-40 h-40 bg-gradient-to-r from-emerald-500/10 to-blue-500/10 rounded-full blur-3xl" />
            
            <h1 className="text-2xl xs:text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold text-center mb-6 sm:mb-8 text-foreground relative">
              <motion.span 
                className="block mb-2 bg-clip-text text-transparent bg-gradient-to-r from-emerald-500 to-blue-500"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.4 }}
              >
                Find amazing rentals
              </motion.span>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.6 }}
              >
                <Cover>at warp speed</Cover>
              </motion.div>
            </h1>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.8 }}
              className="max-w-[95vw] mx-auto"
            >
              <SearchValidation onValidSearch={handleValidSearch} />
            </motion.div>
          </motion.div>
        </div>
      </main>
    </div>
  );
}

export default function App() {
  const { isSignedIn } = useUser();

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route
        path="/sign-in/*"
        element={
          <div className="min-h-screen flex items-center justify-center bg-background">
            <div className="w-full max-w-md bg-white dark:bg-white rounded-lg shadow-sm">
              <SignIn 
                routing="path" 
                path="/sign-in" 
                afterSignInUrl="/chat"
                appearance={{
                  elements: {
                    formButtonPrimary: "bg-black hover:bg-gray-800 text-white",
                    card: "bg-white",
                    headerTitle: "text-gray-900",
                    headerSubtitle: "text-gray-600",
                    socialButtonsBlockButton: "text-gray-900 bg-white border border-gray-300 hover:bg-gray-50",
                    formFieldLabel: "text-gray-900",
                    formFieldInput: "text-gray-900 bg-white border-gray-300",
                    dividerText: "text-gray-600",
                    footerActionLink: "text-blue-600 hover:text-blue-800"
                  }
                }}
              />
            </div>
          </div>
        } 
      />
      <Route
        path="/search"
        element={
          isSignedIn ? <SearchResults /> : <Navigate to="/sign-in" replace />
        }
      />
      <Route
        path="/chat"
        element={
          isSignedIn ? <ChatPage /> : <Navigate to="/sign-in" replace />
        }
      />
      <Route
        path="/property/:id"
        element={
          isSignedIn ? <PropertyDetails /> : <Navigate to="/sign-in" replace />
        }
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}