import { Button } from './button';
import { useNavigate } from 'react-router-dom';
import { useUser, SignInButton } from '@clerk/clerk-react';
import { MapIcon } from './icons';

interface ViewMapButtonProps {
  className?: string;
}

export function ViewMapButton({ className }: ViewMapButtonProps) {
  const { isSignedIn } = useUser();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/search');
  };

  if (!isSignedIn) {
    return (
      <SignInButton mode="modal" afterSignInUrl="/search">
        <Button
          variant="outline"
          size="sm"
          className={`group px-2 sm:px-3 py-1 sm:py-1.5 bg-background hover:bg-accent text-foreground rounded-lg transition-all duration-200 text-xs sm:text-sm flex items-center gap-0 hover:gap-1.5 sm:hover:gap-2 hover:px-3 sm:hover:px-4 ${className}`}
        >
          <span>View Map</span>
          <MapIcon className="w-0 h-3 sm:h-4 group-hover:w-3 sm:group-hover:w-4 transition-all duration-200" />
        </Button>
      </SignInButton>
    );
  }

  return (
    <Button
      variant="outline"
      size="sm"
      onClick={handleClick}
      className={`group px-2 sm:px-3 py-1 sm:py-1.5 bg-background hover:bg-accent text-foreground rounded-lg transition-all duration-200 text-xs sm:text-sm flex items-center gap-0 hover:gap-1.5 sm:hover:gap-2 hover:px-3 sm:hover:px-4 ${className}`}
    >
      <span>View Map</span>
      <MapIcon className="w-0 h-3 sm:h-4 group-hover:w-3 sm:group-hover:w-4 transition-all duration-200" />
    </Button>
  );
}