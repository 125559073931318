import { motion } from 'framer-motion';
import { Property } from '@/types/property';
import { PropertyCard } from '../ui/property-card';

interface ChatMessageProps {
  content: string;
  sender: 'user' | 'assistant';
  properties?: Property[];
}

export function ChatMessage({ content, sender, properties }: ChatMessageProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      className={`flex ${sender === 'user' ? 'justify-end' : 'justify-start'}`}
    >
      <div
        className={`max-w-[85%] rounded-2xl px-4 py-3 ${
          sender === 'user'
            ? 'bg-primary text-primary-foreground'
            : 'bg-muted/50 text-foreground'
        }`}
      >
        <p className="text-sm whitespace-pre-wrap break-words">{content}</p>
        {properties && (
          <div className="mt-4 space-y-4">
            {properties.map((property) => (
              <PropertyCard key={property.id} property={property} variant="compact" />
            ))}
          </div>
        )}
      </div>
    </motion.div>
  );
}