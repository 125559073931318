import { useNavigate } from 'react-router-dom';
import { MapPin, Bed, Bath, Home } from 'lucide-react';
import type { Property } from '../../types/property';

interface PropertyCardProps {
  property: Property;
  variant?: 'default' | 'compact';
}

export function PropertyCard({ property, variant = 'default' }: PropertyCardProps) {
  const navigate = useNavigate();

  if (variant === 'compact') {
    return (
      <div
        onClick={() => navigate(`/property/${property.id}`)}
        className="relative bg-background/50 rounded-lg overflow-hidden hover:bg-accent/25 transition-colors cursor-pointer"
      >
        <div className="flex flex-row items-start gap-3 p-2">
          <div className="relative w-20 h-20 flex-shrink-0 rounded-md overflow-hidden">
            <img
              src={property.images[0]}
              alt={property.title}
              className="w-full h-full object-cover"
            />
            <div className="absolute top-1 right-1 bg-black/75 text-white px-1.5 py-0.5 rounded text-[10px] font-medium">
              {property.price}
            </div>
          </div>

          <div className="flex-1 min-w-0">
            <h3 className="font-medium text-xs truncate">{property.title}</h3>
            <div className="flex items-center gap-1 mt-0.5">
              <MapPin className="w-3 h-3 text-muted-foreground" />
              <span className="text-[10px] text-muted-foreground truncate">{property.location}</span>
            </div>
            <div className="flex items-center gap-3 mt-1">
              <div className="flex items-center gap-1">
                <Bed className="w-3 h-3 text-muted-foreground" />
                <span className="text-[10px] text-muted-foreground">{property.beds}</span>
              </div>
              <div className="flex items-center gap-1">
                <Bath className="w-3 h-3 text-muted-foreground" />
                <span className="text-[10px] text-muted-foreground">{property.baths}</span>
              </div>
              <div className="flex items-center gap-1">
                <Home className="w-3 h-3 text-muted-foreground" />
                <span className="text-[10px] text-muted-foreground">{property.sqft}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      onClick={() => navigate(`/property/${property.id}`)}
      className="relative bg-card rounded-lg overflow-hidden"
    >
      <div className="flex flex-row items-start gap-4">
        <div className="relative w-24 h-24 flex-shrink-0 rounded-md overflow-hidden">
          <img
            src={property.images[0]}
            alt={property.title}
            className="w-full h-full object-cover"
          />
          <div className="absolute top-1 right-1 bg-black/75 dark:bg-white/90 text-white dark:text-black px-1.5 py-0.5 rounded text-xs font-medium">
            {property.price}
          </div>
        </div>

        <div className="flex-1 min-w-0">
          <h3 className="font-medium text-sm text-foreground truncate pr-4">{property.title}</h3>
          
          <div className="flex items-center gap-1 text-muted-foreground mt-1">
            <MapPin className="w-3 h-3 flex-shrink-0" />
            <span className="text-xs truncate">{property.location}</span>
          </div>

          <div className="flex items-center gap-4 mt-2">
            <div className="flex items-center gap-1 text-muted-foreground">
              <Bed className="w-3 h-3" />
              <span className="text-xs">{property.beds}</span>
            </div>
            <div className="flex items-center gap-1 text-muted-foreground">
              <Bath className="w-3 h-3" />
              <span className="text-xs">{property.baths}</span>
            </div>
            <div className="flex items-center gap-1 text-muted-foreground">
              <Home className="w-3 h-3" />
              <span className="text-xs">{property.sqft}</span>
            </div>
          </div>

          <div className="flex items-center justify-between mt-2">
            <span className="text-xs text-emerald-600 dark:text-emerald-400">
              {property.available}
            </span>
            <span className="text-[10px] px-1.5 py-0.5 bg-accent/50 text-muted-foreground rounded">
              {property.type}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
