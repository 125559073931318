import { motion } from 'framer-motion';
import { Loader2 } from 'lucide-react';

export function ChatMessageSkeleton() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex justify-start"
    >
      <div className="bg-card text-card-foreground border shadow-sm rounded-2xl px-4 py-3">
        <div className="flex items-center gap-2">
          <Loader2 className="w-4 h-4 animate-spin" />
          <span className="text-sm text-muted-foreground">Finding properties...</span>
        </div>
      </div>
    </motion.div>
  );
}