import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ClerkProvider } from '@clerk/clerk-react';
import App from './App.tsx';
import './index.css';
import { ThemeProvider } from './components/theme-provider';

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ClerkProvider 
      publishableKey={PUBLISHABLE_KEY}
      appearance={{
        baseTheme: undefined,
        variables: {
          colorPrimary: "#000000",
          colorBackground: "#ffffff",
          colorText: "#000000",
          colorInputText: "#000000",
          colorInputBackground: "#ffffff",
          colorSuccess: "#22c55e"
        },
        elements: {
          card: "bg-white dark:bg-white shadow-none",
          headerTitle: "text-gray-900",
          headerSubtitle: "text-gray-600",
          socialButtonsBlockButton: "text-gray-900 bg-white border border-gray-300 hover:bg-gray-50",
          formFieldLabel: "text-gray-900",
          formFieldInput: "text-gray-900 bg-white border-gray-300",
          dividerLine: "bg-gray-200",
          dividerText: "text-gray-600",
          formButtonPrimary: "bg-black hover:bg-gray-800 text-white",
          footerActionLink: "text-blue-600 hover:text-blue-800",
          identityPreviewText: "text-gray-900",
          identityPreviewEditButton: "text-blue-600 hover:text-blue-800"
        }
      }}
    >
      <BrowserRouter>
        <ThemeProvider defaultTheme="light">
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </ClerkProvider>
  </React.StrictMode>
);