import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeToggle } from './theme-toggle';
import { MessageSquare, Map as MapIcon } from 'lucide-react';
import { SignIn, useUser, UserButton } from '@clerk/clerk-react';
import { mockProperties } from '../data/mock-properties';
import { Button } from './ui/button';
import { motion } from 'framer-motion';
import { GoogleMapComponent } from './map/google-map';
import { PropertyList } from './map/property-list';
import { PropertyFilters } from './map/property-filters';
import type { Property } from '../types/property';

export function SearchResults() {
  const { isSignedIn } = useUser();
  const [selectedProperty, setSelectedProperty] = useState<Property | null>(null);
  const navigate = useNavigate();

  if (!isSignedIn) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-background">
        <div className="max-w-md w-full p-6">
          <SignIn afterSignInUrl="/search" />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background text-foreground">
      <header className="fixed top-0 w-full bg-background/80 backdrop-blur-sm z-50 border-b">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-14">
            <a href="/" className="flex items-center h-10">
              <img
                src="https://logoblack.s3.us-east-1.amazonaws.com/logo-fac-B.png"
                alt="FindACrib Logo"
                className="h-full w-auto object-contain dark:hidden"
              />
              <img
                src="https://logowhite.s3.us-east-1.amazonaws.com/wide+logo+%40+cove+(2).png"
                alt="FindACrib Logo"
                className="h-full w-auto object-contain hidden dark:block"
              />
            </a>
            <div className="flex items-center gap-4">
              <div className="flex bg-accent rounded-full p-1">
                <Button
                  variant="default"
                  size="sm"
                  className="rounded-full"
                >
                  <MapIcon className="w-4 h-4 mr-2" />
                  Map
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  className="rounded-full"
                  onClick={() => navigate('/chat')}
                >
                  <MessageSquare className="w-4 h-4 mr-2" />
                  Chat
                </Button>
              </div>
              <ThemeToggle />
              <UserButton afterSignOutUrl="/" />
            </div>
          </div>
        </div>
      </header>

      <div className="fixed top-14 left-0 right-0 z-40">
        <PropertyFilters />
      </div>

      <main className="pt-28">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="h-[calc(100vh-7rem)] flex"
        >
          <PropertyList
            properties={mockProperties}
            selectedProperty={selectedProperty}
            onPropertySelect={setSelectedProperty}
          />
          <div className="flex-1">
            <GoogleMapComponent 
              properties={mockProperties}
              onPropertySelect={setSelectedProperty}
            />
          </div>
        </motion.div>
      </main>
    </div>
  );
}