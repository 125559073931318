import { motion } from 'framer-motion';
import { Property } from '../../types/property';
import { PropertyCard } from '../ui/property-card';
import { ScrollArea } from '../ui/scroll-area';

interface PropertyListProps {
  properties: Property[];
  selectedProperty: Property | null;
  onPropertySelect: (property: Property) => void;
}

export function PropertyList({ properties, selectedProperty, onPropertySelect }: PropertyListProps) {
  return (
    <div className="w-[400px] h-full bg-background border-r">
      <div className="p-4 border-b">
        <h2 className="text-lg font-semibold">
          {properties.length} Properties Available
        </h2>
      </div>
      <ScrollArea className="h-[calc(100vh-8.5rem)]">
        <div className="divide-y divide-border">
          {properties.map((property) => (
            <motion.div
              key={property.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              onClick={() => onPropertySelect(property)}
              className={`property-list-item ${
                selectedProperty?.id === property.id
                  ? 'bg-accent/50'
                  : ''
              }`}
            >
              <PropertyCard property={property} />
            </motion.div>
          ))}
        </div>
      </ScrollArea>
    </div>
  );
}