import { useEffect, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';
import { ChatMessage } from './chat-message';
import { ChatMessageSkeleton } from './chat-message-skeleton';
import type { ChatMessage as ChatMessageType } from '@/types/chat';

interface ChatMessagesProps {
  messages: ChatMessageType[];
  isTyping: boolean;
}

export function ChatMessages({ messages, isTyping }: ChatMessagesProps) {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, isTyping]);

  return (
    <div className="flex-1 overflow-y-auto">
      <div className="max-w-3xl mx-auto px-4 py-4 space-y-6">
        {messages.length === 0 && (
          <div className="flex flex-col items-center justify-center min-h-[50vh]">
            <p className="text-lg font-medium text-muted-foreground">Welcome to FindACrib</p>
            <p className="text-sm text-muted-foreground mt-1">Start by describing your ideal home</p>
          </div>
        )}
        <AnimatePresence initial={false}>
          {messages.map((message) => (
            <ChatMessage
              key={message.id}
              content={message.content}
              sender={message.sender}
              properties={message.properties}
            />
          ))}
          {isTyping && <ChatMessageSkeleton />}
        </AnimatePresence>
        <div ref={messagesEndRef} className="h-20" />
      </div>
    </div>
  );
}