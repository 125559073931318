import { useNavigate } from 'react-router-dom';
import { MapIcon, MessageSquare } from 'lucide-react';
import { UserButton } from '@clerk/clerk-react';
import { Button } from '../ui/button';
import { ThemeToggle } from '../theme-toggle';

export function ChatHeader() {
  const navigate = useNavigate();

  return (
    <header className="fixed top-0 left-0 right-0 bg-background/80 backdrop-blur-sm z-50 border-b">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-14">
          <div className="flex items-center gap-4">
            <a href="/" className="flex items-center h-10">
              <img
                src="https://logoblack.s3.us-east-1.amazonaws.com/logo-fac-B.png"
                alt="FindACrib Logo"
                className="h-full w-auto object-contain dark:hidden"
              />
              <img
                src="https://logowhite.s3.us-east-1.amazonaws.com/wide+logo+%40+cove+(2).png"
                alt="FindACrib Logo"
                className="h-full w-auto object-contain hidden dark:block"
              />
            </a>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex bg-accent rounded-full p-1">
              <Button
                variant="ghost"
                size="sm"
                className="rounded-full text-foreground hover:text-foreground"
                onClick={() => navigate('/search')}
              >
                <MapIcon className="w-4 h-4 mr-2" />
                Map
              </Button>
              <Button
                variant="default"
                size="sm"
                className="rounded-full"
              >
                <MessageSquare className="w-4 h-4 mr-2" />
                Chat
              </Button>
            </div>
            <ThemeToggle />
            <UserButton afterSignOutUrl="/" />
          </div>
        </div>
      </div>
    </header>
  );
}