import { useCallback, useRef, useState } from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { Property } from '../../data/mock-properties';
import { PropertyCard } from '../ui/property-card';

interface GoogleMapComponentProps {
  properties: Property[];
  onPropertySelect?: (property: Property) => void;
}

const mapOptions = {
  disableDefaultUI: true,
  zoomControl: true,
  styles: [
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#6c6c6c' }]
    },
    {
      featureType: 'water',
      elementType: 'geometry.fill',
      stylers: [{ color: '#e9e9e9' }]
    },
    {
      featureType: 'landscape',
      elementType: 'geometry.fill',
      stylers: [{ color: '#f5f5f5' }]
    }
  ]
};

export function GoogleMapComponent({ properties, onPropertySelect }: GoogleMapComponentProps) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY || ''
  });

  const [selectedProperty, setSelectedProperty] = useState<Property | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);

  const onLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
    
    // Fit bounds to show all properties
    if (properties.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      properties.forEach(property => {
        bounds.extend({
          lat: property.coordinates[0],
          lng: property.coordinates[1]
        });
      });
      map.fitBounds(bounds);
    }
  }, [properties]);

  const handleMarkerClick = (property: Property) => {
    setSelectedProperty(property);
    if (onPropertySelect) {
      onPropertySelect(property);
    }
  };

  if (!isLoaded) {
    return (
      <div className="h-full w-full flex items-center justify-center bg-background">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <GoogleMap
      zoom={12}
      center={{ lat: 40.7128, lng: -74.0060 }}
      mapContainerClassName="w-full h-full"
      options={mapOptions}
      onLoad={onLoad}
    >
      {properties.map((property) => (
        <Marker
          key={property.id}
          position={{
            lat: property.coordinates[0],
            lng: property.coordinates[1]
          }}
          onClick={() => handleMarkerClick(property)}
          icon={{
            url: selectedProperty?.id === property.id 
              ? '/marker-active.svg'
              : '/marker.svg',
            scaledSize: new google.maps.Size(32, 32)
          }}
        />
      ))}

      {selectedProperty && (
        <InfoWindow
          position={{
            lat: selectedProperty.coordinates[0],
            lng: selectedProperty.coordinates[1]
          }}
          onCloseClick={() => setSelectedProperty(null)}
        >
          <div className="max-w-sm">
            <PropertyCard property={selectedProperty} />
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
}